import React, { useState, useEffect } from "react";
import moment from "moment";

const Timer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = moment();
    const target = moment(targetDate);
    const duration = moment.duration(target.diff(now));
    return Math.max(duration.asSeconds(), 0); // Ensure time left is not negative
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [targetDate]);

  // Calculate minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = Math.floor(timeLeft % 60);

  // Format timeLeft as mm:ss
  const formattedTimeLeft = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;

  return <div>{formattedTimeLeft}</div>;
};

export default Timer;
