import Calendar from "react-calendar";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faClock,
  faRectangleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./calendar.css";
import CustomSelect from "../customSelect/Select";
import Timer from "../coutdownTime/countdown";
import moment from "moment";
const Step = {
  BOOKING: "BOOKING",
  QUANTITY: "QUANTITY",
};
const headers = {
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("at") || "Basic YWRtaW46cGFyaXN1bWUxMjNA",
};
const baseUrl = "https://paris-ume.com";
const CalenderComponent = () => {
  const [selectDate, onChange] = useState(new Date());
  const [options, setOptions] = useState();
  const [allSlot, setAllSlot] = useState();
  const [slotForCurrentDate, setSlotForCurrentDate] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [product, setProduct] = useState();
  const [payloadReserver, setPayloadReserver] = useState({
    quantity: 1,
    totalPrice: 1,
    date: "date",
    timeFrom: "",
    timeTo: "",
  });

  const [timeReserver, setTimeReserver] = useState({
    timeBook: "",
    targetTime: "",
  });

  const [loading, setLoading] = useState(false);
  const [remainingSlot, setRemainingSlot] = useState(0);

  // Get the 'id' parameter value
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const id = params.get("id");

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setLoading(true);
        const newRespond = await fetch(
          `${baseUrl}/wp-json/booking/v1/slots/${id}`,
          {
            method: "GET",
            headers: headers,
          }
        );
        const newData = await newRespond.json();
        const { mon_fri_slots, product_name, product_price, sat_sun_slots } =
          newData;
        setProduct({ name: product_name, price: product_price });
        setAllSlot({
          mon_fri_slots,
          sat_sun_slots,
        });
        setPayloadReserver({
          ...payloadReserver,
          totalPrice: product_price,
        });
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [id]);

  useEffect(() => {
    if (allSlot) {
      if (isWeekday(selectDate)) {
        setSlotForCurrentDate(allSlot["mon_fri_slots"]);
      } else {
        setSlotForCurrentDate(allSlot["sat_sun_slots"]);
      }
    }
    setPayloadReserver({
      ...payloadReserver,
      date: moment(selectDate).format("YYYY-MM-DD"),
    });
  }, [allSlot, selectDate]);

  useEffect(() => {
    if (slotForCurrentDate && selectDate) {
      const newOption = slotForCurrentDate
        .filter((item) => {
          // Combine selectDate with item's time_slot_from to create a full datetime
          const [hours, minutes] = item.time_slot_from.split(":").map(Number);
          const itemDateTime = new Date(selectDate);
          itemDateTime.setHours(hours, minutes, 0, 0);

          // Get the current datetime
          const currentDateTime = new Date();

          // Compare the item's datetime with the current datetime
          return itemDateTime > currentDateTime;
        })
        .map((item) => {
          return {
            value: `${item.time_slot_from}`,
            label: `${item.time_slot_from} - ${item.time_slot_to}`,
          };
        });

      setOptions(newOption);
      setSelectedOption(newOption[0]);
    }
  }, [slotForCurrentDate, selectDate]);

  useEffect(() => {
    if (selectedOption) {
      const [from, to] = selectedOption.label.split("-");

      setPayloadReserver({
        ...payloadReserver,
        timeFrom: from.trim(),
        timeTo: to.trim(),
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    localStorage.setItem("product", JSON.stringify(payloadReserver));
  }, [payloadReserver]);
  useEffect(() => {
    if (timeReserver.timeBook && timeReserver.targetTime) {
      localStorage.setItem("time-book", JSON.stringify(timeReserver));
    }
  }, [timeReserver]);

  // Check time reserver remaining
  useEffect(() => {
    const timeBookString = localStorage.getItem("time-book");
    if (timeBookString) {
      const timeObj = JSON.parse(timeBookString);
      const { timeBook, targetTime } = timeObj;
      if (targetTime && moment().isBefore(targetTime)) {
        setTimeReserver({ timeBook: timeBook, targetTime: targetTime });
        setIsDisplayCountTime(true);
      }
    }
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const newRespond = await fetch(
        `${baseUrl}/wp-json/booking/v1/remaining-slot`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            product_id: id,
            date: payloadReserver.date,
            time_from: payloadReserver.timeFrom,
            time_to: payloadReserver.timeTo,
          }),
        }
      );
      const respondObject = await newRespond.json();
      const { remaining_slot } = respondObject;
      setRemainingSlot(remaining_slot);
      if (remaining_slot > 0) {
        setStep(Step.QUANTITY);
      } else {
        alert(
          "La quantité restante n'est pas suffisante, veuillez choisir un autre créneau horaire"
        );
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleReserver = async () => {
    try {
      setLoading(true);
      const respond = await fetch(
        `${baseUrl}/wp-json/booking/v1/create`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            product_id: id,
            date: payloadReserver.date,
            time_from: payloadReserver.timeFrom,
            time_to: payloadReserver.timeTo,
            quantity: payloadReserver.quantity,
          }),
        }
      );

      if (respond.status === 200) {
        setTimeReserver({
          timeBook: moment(),
          targetTime: moment().add(15, "minute"),
        });
        setIsDisplayCountTime(true);
        const respondObject = await respond.json();
        const { booking_id } = respondObject;
        window.location.href = `/checkout/?add-to-cart=${id}&quantity=${payloadReserver.quantity}&booking-id=${booking_id}`;
      } else {
        alert(
          "Quelque chose ne va pas, veuillez réessayer ou changer de créneau horaire."
        );
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    // 0 is Sunday, 6 is Saturday
    return day !== 0 && day !== 6;
  };
  const [step, setStep] = useState(Step.BOOKING);

  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };
  const handleOnChangeQuantity = (quantity) => {
    setPayloadReserver({
      ...payloadReserver,
      quantity: quantity,
      totalPrice: (product.price * quantity).toFixed(2),
    });
  };

  const [isDisplayCountTime, setIsDisplayCountTime] = useState(false);

  const disablePastDates = ({ date, view }) => {
    // Disable past dates in month view
    if (view === "month") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return date < today;
    }
  };

  const renderContent = () => {
    switch (step) {
      case Step.BOOKING:
        return (
          <div className="calendar_wp">
            <div className="header_name">Réserver</div>
            <Calendar
              onChange={onChange}
              value={selectDate}
              locale="en"
              tileDisabled={disablePastDates}
            />
            <CustomSelect
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              disabled={loading}
            />
            <button
              className="submit-button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <div className="spinner"></div> : "Submit"}
            </button>
          </div>
        );
      case Step.QUANTITY:
        return (
          <div className="quantity_wp">
            <div className="header_quantity">
              <div
                className="back-button"
                onClick={() => setStep(Step.BOOKING)}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              <div className="header_name">Quantité</div>
            </div>
            <p className="instruction">Entrez le nombre de personnes</p>
            <p className="date">
              {formatDate(selectDate)} {selectedOption.label.split("-")[0]}
            </p>
            <table>
              <thead>
                <tr>
                  <th>Catégorie</th>
                  <th>Prix</th>
                  <th>Quantité</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{product?.name}</td>
                  <td>{product?.price} €</td>
                  <td>
                    <input
                      type="number"
                      value={payloadReserver.quantity}
                      min="1"
                      max={remainingSlot}
                      onChange={(e) => handleOnChangeQuantity(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="line"></div>
            <div className="total">
              <span>Prix total:</span>
              <span>{payloadReserver.totalPrice} €</span>
            </div>
            <div className="reserver-button">
              <button
                className="submit-button"
                onClick={handleReserver}
                disabled={loading}
              >
                {loading ? (
                  <div className="spinner"></div>
                ) : (
                  "Réserver maintenant"
                )}
              </button>
            </div>
          </div>
        );

      default:
        return (
          <div className="calendar_wp">
            <div className="header_name">Réserver</div>
            <Calendar
              onChange={onChange}
              value={selectDate}
              locale="en"
              tileDisabled={disablePastDates}
            />
            <CustomSelect
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              disabled={loading}
            />
            <button
              className="submit-button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <div className="spinner"></div> : "Submit"}
            </button>
          </div>
        );
    }
  };
  return (
    <div>
      {renderContent()}
      {isDisplayCountTime && (
        <div className="calender_footer">
          <div>
            <span className="booking" onClick={() => setStep(Step.QUANTITY)}>
              Your booking
            </span>{" "}
            is reserved until{" "}
            {moment(timeReserver.targetTime).format("DD/MM/YYYY HH:mm")}
          </div>
          <div className="right">
            <FontAwesomeIcon
              icon={faClock}
              size={"lg"}
              className="icon-clock"
            />
            <span className="time-text">
              <Timer targetDate={timeReserver.targetTime} />
            </span>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faRectangleXmark}
              size={"lg"}
              onClick={() => setIsDisplayCountTime(false)}
              className="icon-close"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default CalenderComponent;
