import React, { useState, useEffect, useRef } from "react";
import "./Select.css";

const CustomSelect = ({
  options,
  selectedOption,
  setSelectedOption,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const customSelectRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        customSelectRef.current &&
        !customSelectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-select-wrapper" ref={customSelectRef}>
      <div
        className={`custom-select ${disabled ? "disabled" : ""}`}
        onClick={handleToggleDropdown}
      >
        <div className="custom-select-trigger">
          <span>
            {selectedOption ? selectedOption.label : "Select an option"}
          </span>
          <div className="arrow"></div>
        </div>
        {isOpen && (
          <div className="custom-options">
            {options.map((option) => (
              <span
                key={option.value}
                className={`custom-option ${
                  selectedOption && selectedOption.value === option.value
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
