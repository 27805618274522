import logo from "./logo.svg";
import "./App.css";
import CalenderComponent from "./components/calendar/calendar";
import "react-calendar/dist/Calendar.css";

function App() {
  return (
    <div>
      <CalenderComponent />
    </div>
  );
}

export default App;
